import React from 'react';
import { below } from 'src/components/global/mediaqueries';
import AspectWrapper from 'src/components/AspectWrapper';
import BergStar from 'src/icons/BergStar';
import formatDate from 'src/utils/formatDate';
import HeaderArticleText from './ArticleText';
import Image from 'src/components/Image';
import Link from 'src/components/Link';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';
import VerticalHeading from './VerticalHeading';

const ArticleWrapper = styled(Link)`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    color: ${theme.color.black};
    align-items: center;
    justify-content: space-between;
    padding-top: 76px;
    padding-bottom: 76px;

    ${below.desktop_s} {
        display: none;
    }
`;

const StyledVerticalHeading = styled(VerticalHeading)`
    position: absolute;
    top: 80px;
    right: 39px;
`;

const ImageWrapper = styled('div')`
    width: 45%;
    background-color: ${theme.color.productcard};
`;

const TextWrapper = styled('div')`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LogoWrapper = styled('div')`
    height: 50%;
    margin: auto;
`;

const Article = ({ data, closeMenus }) => {
    const { article, heading } = data;
    const { acfData, postDate, postTitle, mainCategory } = article;

    const formattedDate = postDate ? formatDate(postDate) : '';
    const image = acfData.postImage;
    const category = (mainCategory && mainCategory.name) || '';
    const link = acfData?.permalink || '';

    return (
        article && (
            <ArticleWrapper src={link} onClick={closeMenus}>
                <StyledVerticalHeading text={heading}/>
                <ImageWrapper>
                    <AspectWrapper ratio={[5 / 3, null, 5 / 3]}>
                        {image ? (
                            <Image
                                fluid={image}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                }}
                            />
                        ) : (
                            <LogoWrapper>
                                <BergStar height="100%" width="100%" />
                            </LogoWrapper>
                        )}
                    </AspectWrapper>
                </ImageWrapper>
                <TextWrapper>
                    <HeaderArticleText date={formattedDate} tag={category} text={postTitle} />
                </TextWrapper>
            </ArticleWrapper>
        )
    );
};

Article.propTypes = {
    closeMenus: PropTypes.func.isRequired,
    data: PropTypes.shape({
        article: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.shape({
                acfData: PropTypes.object,
                mainCategory: PropTypes.object,
                postDate: PropTypes.string,
                postTitle: PropTypes.string,
            }),
        ]),
        heading: PropTypes.string,
    }),
};

Article.defaultProps = {
    data: {
        article: false,
        heading: '',
    },
};

export default Article;
